import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="footer-nav col-sm-4 col-xs-12">
              <ul>
                <li>
                  <a
                    href="/"
                    title="Opens internal link in current window"
                    className="internal-link"
                  >
                    Startseite
                  </a>
                </li>
                <li>
                  <a
                    href="/produkte"
                    title="Opens internal link in current window"
                    className="internal-link"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <a
                    href="/markenhistorie"
                    title="Opens internal link in current window"
                    className="internal-link"
                  >
                    Markenhistorie
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-content-teaser col-sm-4 col-xs-12">
              <h2>Markenhistorie</h2>
              <p>
                {" "}
                Seit mehr als 50 Jahren pflegt{" "}
                <strong>
                  bebe<sup>®</sup>
                </strong>{" "}
                junge Haut zart und geschmeidig:
                <br />
                Die Geschichte von{" "}
                <strong>
                  bebe Zartpflege<sup>®</sup>
                </strong>
                .{" "}
              </p>
              <p>
                <a
                  href="/markenhistorie"
                  target="_self"
                  className="textlink-intern"
                >
                  Jetzt entdecken
                </a>
              </p>
            </div>
            <div className="footer-gewinnspiel col-sm-4 col-xs-12"> </div>
          </div>
          <div id="footer-sec-nav" className="row">
            <div className="col-sm-12 col-xs-12">
              <p>
                <a href="/menu/faq" className="textlink-small">
                  FAQ
                </a>
                <a href="/menu/kontakt" className="textlink-small">
                  Kontakt
                </a>
                <a
                  href="http://www.jnjgermany.de/"
                  target="_blank"
                  className="external-link-new-window"
                >
                  www.jnjgermany.de
                </a>
                <a href="/menu/impressum" className="textlink-small">
                  Impressum
                </a>
                <a
                  href="/datenschutzbedingungen"
                  className="textlink-small"
                >
                  Datenschutz
                </a>
                <a
                  href="/menu/rechtliche-hinweise"
                  className="textlink-small"
                >
                  Rechtliche Hinweise
                </a>{" "}
                <a
                  href="/menu/cookie-information"
                  className="textlink-small"
                >
                  Cookies
                </a>
                &nbsp;
                <a href="/menu/allg-gewinnspielbedingungen">
                  Allg. Gewinnspielbedingungen
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-xs-12">
              <p>
                <a id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie-Einstellungen</a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-xs-12">
              <p className="small">
                {" "}
                © Johnson &amp; Johnson GmbH 2019. Diese Webseite wird
                veröffentlicht von der Johnson &amp; Johnson GmbH, die die
                alleinige Verantwortung für deren Inhalt trägt.
                <br /> Die Webseite dient Besuchern aus Deutschland und
                Österreich.
                <br /> Letzte Änderung am 28.11.2019.{" "}
                <b>
                  bebe<sup>®</sup>
                </b>{" "}
                ist eine eingetragene Marke der Johnson &amp; Johnson GmbH.
                <br />{" "}
                <a href="http://www.jnjgermany.de" target="_blank">
                  www.jnjgermany.de
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
