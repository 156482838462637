import React from "react";

class Header extends React.Component {
  render() {
    return (
      <header className="navbar navbar-fixed-top hiddennav" role="banner">
        <div className="container">
          {" "}
          <a
            href="/index.html"
            title="bebe Zartpflege®"
            target="_self"
            className="navbar-brand"
          >
            <em>
              bebe Zartpflege<sup>®</sup>
            </em>
          </a>
          <div className="navbar-header">
            <button
              className="navbar-toggle"
              type="button"
              data-toggle="collapse"
              data-target=".bs-navbar-collapse"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar" /> <span className="icon-bar" />{" "}
              <span className="icon-bar" /> <span className="navbar-hint" />{" "}
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
