import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="stage" className="produkte animated-stage">
          <div className="container">
            <div className="row text-center">
              <div className="stage-text col-md-10 col-md-offset-1">
                <h1>Unsere Produkte</h1>
                <p>
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  ist speziell auf die Bedürfnisse junger Haut abgestimmt und
                  pflegt sie zart und geschmeidig – seit mehr als 50 Jahren.
                </p>
              </div>
              <div className="stage-image produkte col-xs-12">
                <img
                  className="stage-bg desktop"
                  alt="bebe Zartpflege®"
                  src="/fileadmin/images/produkte/produkte.stage.img.png"
                  width="360"
                  height="310"
                />
                <img
                  className="stage-bg mobile"
                  alt="bebe Zartpflege®"
                  src="/fileadmin/images/produkte/produkte.stage.mobile.png"
                  width="633"
                  height="576"
                />
                <div className="animated-items desktop-view">
                  {" "}
                  <a
                    href="/produkte/#zartcreme-dose"
                    className="textlink-scroll animated-item item-1"
                  >
                    <img
                      src="/fileadmin/images/produkte/produkte.stage.dose.png"
                      alt
                    />
                  </a>{" "}
                  {/* <a
                    href="/produkte/#MildeHandseife"
                    className="textlink-scroll animated-item item-3"
                  >
                    <img
                      src="/fileadmin/images/produkte/produkte.stage.shower.png"
                      alt
                    />
                  </a>{" "} */}
                  <a
                    href="/produkte/#zartpflege-milk"
                    className="textlink-scroll animated-item item-4"
                  >
                    <img
                      src="/fileadmin/images/produkte/bebe_Zartpflege_Pflegelotion_new(1).png"
                      alt
                    />
                  </a>{" "}
                  {/* <a
                    href="/produkte/#reinigungstuecher"
                    className="textlink-scroll animated-item item-5"
                  >
                    {" "}
                    <img
                      src="/fileadmin/images/produkte/produkte.stage.reinigungstuecher.png"
                      alt
                    />{" "}
                  </a>{" "} */}
                  <a
                    href="/produkte/#zartpflege-shampoo"
                    className="textlink-scroll animated-item item-6"
                  >
                    <img
                      src="/fileadmin/images/produkte/bebe-zartpflege-Shampoo.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/produkte/#shampoo"
                    className="textlink-scroll animated-item item-9"
                  >
                    <img
                      src="/fileadmin/images/produkte/produkte.shampoo.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/produkte/#zartpflege-schaum"
                    className="textlink-scroll animated-item item-8"
                  >
                    <img
                      src="/fileadmin/images/produkte/bebe_Zartpflege_Schaumbad_und_Du-removebg-preview.png"
                      alt
                    />
                  </a>{" "}
                </div>
                <div className="animated-items mobile-view">
                  {" "}
                  <a
                    href="/produkte/produkte/#Lippenpflegemit"
                    className="textlink-scroll animated-item item-neu animate"
                    style={{
                      opacity: "1"
                    }}
                  >
                    <img
                      src="/fileadmin/images/produkte/produkte.stage.reinigungstuecher.mobile.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/produkte/produkte/#zartcreme-dose"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-1 animate"
                      src="/fileadmin/images/produkte/produkte.stage.dose.mobile.png"
                      alt
                      style={{
                        opacity: "1"
                      }}
                    />
                  </a>{" "}
                  <a
                    href="/produkte/produkte/#zartpflege-shower-shampoo"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-3 animate"
                      src="/fileadmin/images/produkte/produkte.stage.shower.mobile.png"
                      alt
                      style={{
                        opacity: "1"
                      }}
                    />
                  </a>{" "}
                  <a
                    href="/produkte/produkte/#zartpflege-milk"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-4 animate"
                      src="/fileadmin/images/produkte/produkte.stage.milk.mobile.png"
                      alt
                      style={{
                        opacity: "1"
                      }}
                    />
                  </a>{" "}
                  <a
                    href="/produkte/produkte/#zartpflege-shampoo"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-4 animate"
                      src="/fileadmin/images/produkte/bebe-zartpflege-Shampoo.png"
                      alt
                      style={{
                        opacity: "1"
                      }}
                    />
                  </a>{" "}
                  <a
                    href="/produkte/produkte/#zartpflege-schaum"
                    className="textlink-scroll item-8"
                  >
                    <img
                      className="animated-item item-4 animate"
                      src="/fileadmin/images/produkte/produkte.stage.shower-mobile.png"
                      alt
                      style={{
                        opacity: "1"
                      }}
                    />
                  </a>{" "}
                  <a
                    href="/produkte/produkte/#zartpflege-schaum"
                    className="textlink-scroll item-8"
                  >
                    <img
                      className="animated-item item-4 animate"
                      src="/fileadmin/images/produkte/produkte-stage-schaumbad-mobile.png"
                      alt
                      style={{
                        opacity: "1"
                      }}
                    />
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="bubbles-container">
              <div className="bubble1" />
              <div className="bubble2" />
              <div className="bubble3" />
              <div className="bubble4" />
            </div>
          </div>
        </div>
        <div className="scroll-bottom-arrow">
          <div className="arrow-animated" />
          <div className="arrow-hint" />
        </div>
        <div id="content" className="produkte">
          <div className="container">
            {" "}
            <a name="ZartcremeinderDose" />
            <div id="zartcreme-dose" className="row">
              <div className="content-heading col-sm-6">
                <h2>Zartcreme</h2>
              </div>
              <div className="content-image text-center col-sm-6 pull-right">
                {" "}
                <a id="bebe16" />
                <div className="image-wrapper">
                  {" "}
                  <img
                    alt="bebe Zartcreme"
                    src="/fileadmin/images/produkte/produkte.content.zartcreme.desktop.png"
                    width="392"
                    height="383"
                    className="desktop"
                  />
                  <img
                    alt="bebe Zartcreme"
                    src="/fileadmin/images/produkte/produkte.content.zartcreme.mobile.png"
                    width="640"
                    height="667"
                    className="mobile"
                  />
                </div>
              </div>
              <div className="content-text col-sm-6">
                <p>
                  Kleiner Schatz, weißt du eigentlich...
                  <br />
                  ... wie lieb dich alle haben? Wenn du läufst, läufst und
                  läufst und die ganze Welt umarmen willst. Wenn du lachst, bis
                  alle mitlachen und keiner weiß, warum. Wenn du uns zeigst,
                  dass es gar nicht schlimm ist, mal hinzufallen. Weil man ja
                  einfach wieder aufstehen kann. Du machst jeden fröhlich,
                  einfach so. Mit der <strong>bebe® Zartcreme</strong> möchten
                  wir dir ein bisschen Freude zurückgeben: Eine besonders milde
                  Hautpflege, die deine Haut vor dem Austrocknen schützt und
                  schnell einzieht. Und Vitamin E ist auch mit drin.
                </p>
                <a
                  className="toggle-link"
                  href="/produkte/#inhaltsstoffe"
                >
                  Inhaltsstoffe
                </a>
                <div id="inhaltsstoffe" className="toggle-content">
                  <p>
                    [PR-013813
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      ]<br />
                    </span>
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      Aqua, Paraffinum Liquidum, Cera Microcristallina,
                      Petrolatum, Propylene Glycol, Lanolin, Methyl Glucose
                      Isostearate, Glycerin, Diisostearoyl Polyglyceryl-3 Dimer
                      Dilinoleate, Hydrogenated Castor Oil, Sorbitol, Magnesium
                      Sulfate, Butylene Glycol, Pentylene Glycol, Zinc Stearate,
                      Tocopheryl Acetate, Phenoxyethanol, Parfum, Citral.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <a name="schaumbad-und-dusche" />
            <div id="zartpflege-schaum" className="row">
              <div className="content-heading col-sm-6 pull-right">
                <h2>Schaumbad und Dusche</h2>
              </div>
              <div className="content-image text-center col-sm-6">
                {" "}
                <a id="bebe295" />
                <div className="image-wrapper">
                  {" "}
                  <img
                    alt="bebe Schaumbad"
                    src="/fileadmin/images/produkte/bebe_Zartpflege_Schaumbad_und_Du-removebg-preview.png"
                    width="250"
                    height="480"
                    className="desktop"
                  />{" "}
                  <img
                    alt="bebe Schaumbad"
                    src="/fileadmin/images/produkte/produkte-stage-schaumbad-mobile.png"
                    width="200"
                    height="400"
                    className="mobile"
                  />{" "}
                </div>
              </div>
              <div className="content-text col-sm-6">
                <p>
                  Kleiner Schatz, weißt du eigentlich...
                  <br />
                  …wie lieb dich alle haben? Wenn du vor lauter Spielzeug in der
                  Badewanne selber keinen Platz mehr hast? Wenn du mit Karacho
                  in eine Pfütze hüpfst und dir ganz egal ist, was (oder wer!)
                  dabei alles schmutzig wird? Du machst jeden fröhlich, einfach
                  so. Mit dem{" "}
                  <strong>bebe Zartpflege® Schaumbad und Dusche</strong> möchten
                  wir dir ein bisschen Freude zurückgeben: Es säubert sanft und
                  sorgt für mehr Spaß im Bad ohne die empfindliche Kinderhaut
                  auszutrocknen. Dank der KEINE TRÄNEN MEHR™ Formulierung brennt
                  es nicht in den Augen. Dermatologisch getestet.
                </p>
                <a
                  className="toggle-link"
                  href="/produkte/#inhaltsstoffe"
                >
                  Inhaltsstoffe
                </a>
                <div id="inhaltsstoffe" className="toggle-content">
                  <p>
                    [PR-018686]
                    <br />
                    Aqua, Cocamidopropyl Betaine, PEG-80 Sorbitan Laurate,
                    Glycerin, PEG-150 Pentaerythrityl Tetrastearate,
                    Ethylhexylglycerin, Coconut Acid, Glycol Distearate, PPG-2
                    Hydroxyethyl Cocamide, Decyl Glucoside, Sodium Methyl Cocoyl
                    Taurate, Laureth-4, Sodium Chloride, Citric Acid, Formic
                    Acid, Phenoxyethanol, Sodium Benzoate, Parfum.
                  </p>
                </div>
              </div>
            </div>
            <a name="Glanzshampoo" />
            <div id="zartpflege-shampoo" className="row">
              <div className="content-heading col-sm-6">
                <h2>Glanzshampoo</h2>
              </div>
              <div className="content-image text-center col-sm-6 pull-right">
                {" "}
                <a id="bebe287" />
                <div className="image-wrapper">
                  {" "}
                  <img
                    alt="bebe® Shampoo"
                    src="/fileadmin/images/produkte/bebe-zartpflege-grafiken-content-shampoo.png"
                    width="640"
                    height="566"
                  />{" "}
                </div>
              </div>
              <div className="content-text col-sm-6">
                <p>
                  Kleiner Schatz, weißt du eigentlich...
                  <br />
                  ... wie lieb dich alle haben?{" "}
                  <span
                    style={{
                      "line-height": "1.42857143"
                    }}
                  >
                    Wenn du Mama durchs Haar wuschelst und ihr sagst, sie soll
                    sich keine Sorgen machen, du schaffst das schon mit dem
                    doofen Jungen in der Kita? Wenn du danach erzählst, dass er
                    jetzt dein bester Freund ist? Du machst jeden fröhlich,
                    einfach so. Mit dem{" "}
                    <strong>bebe Zartpflege® Glanz Shampoo</strong> möchten wir
                    dir ein bisschen Freude zurückgeben: Es gibt deinem Haar
                    natürlichen Glanz und lässt es seidig und gesund aussehen.
                    Dank der KEINE TRÄNEN MEHR™ Formulierung brennt es nicht in
                    den Augen. Ein Tropfen Arganöl ist auch mit drin.
                    <br />
                  </span>
                </p>
                <a
                  className="toggle-link"
                  href="/produkte/#inhaltsstoffe"
                >
                  Inhaltsstoffe
                </a>
                <div id="inhaltsstoffe" className="toggle-content">
                  <p>
                    [PR-018706]
                    <br />
                    Aqua, Cocamidopropyl Betaine, Decyl Glucoside, Sodium Methyl
                    Cocoyl
                    <br />
                    Taurate, Glycerin, Silk Amino Acids, Hydroxypropyl Guar
                    Hydroxypropyltrimonium Chloride, Polyquaternium-10, Argania
                    Spinosa Kernel Oil (Argan Oil), Glycol Distearate, Coconut
                    Acid, Sodium Cocoyl Isethionate, PEG-80 Sorbitan Laurate,
                    PEG-150 Distearate, Sodium Chloride, Disodium EDTA, Citric
                    Acid, BHT, Sodium Benzoate, Parfum.
                  </p>
                </div>
              </div>
            </div>
            <a name="Pflegelotion" />
            <div id="zartpflege-milk" className="row">
              <div className="content-heading col-sm-6 pull-right">
                <h2>Pflegelotion</h2>
              </div>
              <div className="content-image text-center col-sm-6">
                {" "}
                <a id="bebe87" />
                <div className="image-wrapper">
                  {" "}
                  <img
                    alt="bebe® sanfte milk"
                    src="/fileadmin/images/produkte/bebe_Zartpflege_Pflegelotion_new(1).png"
                    width="262"
                    height="421"
                    className="desktop"
                  />{" "}
                  <img
                    alt="bebe® sanfte milk"
                    src="/fileadmin/images/produkte/produkte.content.milk.mobile.png"
                    width="640"
                    height="566"
                    className="mobile"
                  />{" "}
                </div>
              </div>
              <div className="content-text col-sm-6 pull-right">
                <p>
                  Kleiner Schatz, weißt du eigentlich...
                  <br />
                  ... wie lieb dich alle haben? Wenn du dir einen Milchbart
                  wachsen lässt und beim Schmusen schnurrst wie eine kleine
                  Katze? Wenn du Bäume rauf- und runterkletterst, und das besser
                  machst als es die kleine Katze könnte? Du machst jeden
                  fröhlich, einfach so. Mit der{" "}
                  <strong>bebe Zartpflege® Pflegelotion</strong> möchten wir dir
                  ein bisschen Freude zurückgeben: Eine besonders milde
                  Hautpflege, die deiner Haut 24h intensiv Feuchtigkeit spendet
                  und schnell einzieht. Für ein softes Hautgefühl schon ab der
                  ersten Anwendung. Dermatologisch getestet.
                </p>
                <a
                  className="toggle-link"
                  href="/produkte/#inhaltsstoffe"
                >
                  Inhaltsstoffe
                </a>
                <div id="inhaltsstoffe" className="toggle-content">
                  <p>
                    [PR-018677]
                    <br />
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      Aqua, Glycerin, Isopropyl Palmitate, Glyceryl Stearate,
                      Cetyl Alcohol, Cetearyl Alcohol, Cocos Nucifera (Coconut)
                      Oil, Dimethicone, Ethylhexylglycerin, Palmitic Acid,
                      Stearic Acid, Polysorbate 20, p-Anisic Acid, Titanium
                      Dioxide, Magnesium Aluminum Silicate, Carbomer, Xanthan
                      Gum, Sodium Hydroxide, Potassium Bitartrate,
                      Phenoxyethanol, Parfum.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* <a name="MildeHandseife" />
            <div id="MildeHandseife" className="row">
              <div className="content-heading col-sm-6">
                <h2>Milde Handseife</h2>
              </div>
              <div className="content-image text-center col-sm-6 pull-right">
                {" "}
                <a id="bebe84" />
                <div className="image-wrapper">
                  {" "}
                  <img
                    alt="bebe® sanftes shower & shampoo"
                    src="/fileadmin/images/produkte/produkte.content.shower_shampoo.png"
                    width="204"
                    height="377"
                    className="desktop"
                  />{" "}
                  <img
                    alt="bebe® sanftes shower & shampoo"
                    src="/fileadmin/images/produkte/produkte.content.shower_shampoo.mobile.png"
                    width="640"
                    height="537"
                    className="mobile"
                  />{" "}
                </div>
              </div>
              <div className="content-text col-sm-6">
                <p>
                  Kleiner Schatz, weißt du eigentlich...
                  <br />
                  ... wie lieb dich alle haben? Wenn du es irgendwie schaffst,
                  dein Abendessen im ganzen Gesicht zu verteilen? Wenn du nach
                  dem Buddeln im Sand die Wohnung in eine Wüste verwandelst? Du
                  machst jeden fröhlich, einfach so. Mit der{" "}
                  <strong>bebe Zartpflege® Milde Handseife</strong> möchten wir
                  dir ein bisschen Freude zurückgeben: Schmutz und Bakterien
                  werden effektiv entfernt, sodass zukünftigen Erkundungstouren
                  nichts mehr im Weg steht. Gleichzeitig ist die Formulierung
                  trotzdem sanft zur Haut. Dermatologisch getestet.
                  pH-hautneutral.

                  Entfernt Schmutz und Keime bis zu 99%* und schützt gleichzeitig empfindliche Haut**
                  <br />
                  *Test durchgeführt mit E.coli 
                  **schützt vor Trockenheit
                </p>
                <a
                  className="toggle-link"
                  href="/produkte/#inhaltsstoffe"
                >
                  Inhaltsstoffe
                </a>
                <div id="inhaltsstoffe" className="toggle-content">
                  <p>
                    [PR-018683]
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      Aqua, Cocamidopropyl Betaine, PEG-80 Sorbitan Laurate,
                      Glycerin, PEG-150
                      <br />
                      Pentaerythrityl Tetrastearate, Aspalathus Linearis
                      (Rooibos tea) Leaf/Stalk Extract,
                      <br />
                      Camellia Sinensis (Green Tea) Leaf Extract, Nigella Sativa
                      (Black Cumin) Seed Oil,
                      <br />
                      Ethylhexylglycerin, Mel (Honey), Coconut Acid, Decyl
                      Glucoside, PPG-2 Hydroxyethyl
                      <br />
                      Cocamide, Sodium Methyl Cocoyl Taurate, Sodium Chloride,
                      Citric Acid, Lactic acid,
                      <br />
                      Phenoxyethanol, Potassium Sorbate, Sodium Benzoate,
                      Parfum.
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
            <a name="shampoo" />
            <div id="shampoo" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Stärkendes Shampoo und Spülung</h2>
              </div>
              <div className="content-image col-xs-12 space-top-140 col-sm-6">
                {" "}
                <a id="bebe248" />
                <div className="image-wrapper">
                  <img
                    alt="bebe Reinigungstücher"
                    src="/fileadmin/images/produkte/produkte.shampoo.png"
                    width="414"
                    height="190"
                    className="desktop"
                  />
                  <img
                    alt="bebe Reinigungstücher"
                    src="/fileadmin/images/produkte/produkte.shampoo-mobile.png"
                    width="640"
                    height="680"
                    className="mobile"
                  />
                </div>
              </div>
              <div className="content-text col-xs-12 col-sm-6 pull-right">
                <div className="indent">
                  <p>
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                    Kleiner Schatz, weißt du eigentlich...
                  <br />
                  ... wie lieb dich alle haben? Wenn du mit den
                  Füßen aufstampfst, weil Mama ja soo gemein ist 
                  und dich nicht nur mit deinem Lieblingsbadeanzug 
                  bekleidet in den Kindergarten gehen lässt? Wenn 
                  wir dir dein Lieblingsbuch so oft vorlesen müssen,
                  bis du es auswendig kannst? Du machst jeden fröhlich,
                  einfach so. Mit dem bebe Zartpflege® Stärkendes 
                  Shampoo & Spülung möchten wir dir ein bisschen Freude
                  zurückgeben. Es entwirrt dein Haar während des Waschens.
                  Deshalb ziept das Kämmen später nicht.
                    </span>
                  </p>
                </div>
                <a
                  className="toggle-link"
                  href="/produkte/#inhaltsstoffe"
                >
                  Inhaltsstoffe
                </a>
                <div id="inhaltsstoffe" className="toggle-content">
                  <p>
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      [PR-
                    </span>
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      <span
                        style={{
                          "line-height": "1.42857143"
                        }}
                      >
                        018980
                      </span>
                      ]<br />
                    </span>
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                    Aqua, Cocamidopropyl Betaine, Decyl Glucoside, 
                    Sodium Methyl Cocoyl Taurate, Glycerin, Triticum 
                    Vulgare (Wheat) Bran Extract, Tocopheryl Acetate, 
                    Tocopherol, Hydroxypropyl Guar Hydroxypropyltrimonium 
                    Chloride, Coconut Acid, Polyquaternium-10, Glycol Distearate,
                    Trideceth-9, PEG-40 Hydrogenated Castor oil, PEG-80 Sorbitan 
                    Laurate, Sodium Cocoyl Isethionate, Propylene Glycol, 
                    PEG-150 Distearate, Sodium Chloride, Disodium EDTA, 
                    Citric Acid, Lactic acid, BHT, Potassium Sorbate, Sodium Benzoate, Parfum.
                      <br />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          
           {/* <a name="Lippenpflegemit" />
            <div id="Lippenpflegemit" className="row">
              <div className="content-heading col-sm-6">
                <h2>Lippenpflege mit fruchtigem Kirschduft</h2>
              </div>
              <div className="content-image text-center col-sm-6 pull-right">
                {" "}
                <a id="reinigungstuecher" />
                <div className="image-wrapper">
                  {" "}
				  <img
                    alt="bebe® sanftes shower & shampoo"
                    src="/fileadmin/images/produkte/produkte.reinigungstuecher.png"
                    width="640"
                    height="537"
                    className="desktop"
                  />{" "}
                  <img
                    alt="bebe® sanftes shower & shampoo"
                    src="/fileadmin/images/produkte/produkte.stage.reinigungstuecher.mobile.png"
                    width="204"
                    height="377"
                    className="mobile"
                  />{" "}
                </div>
              </div>
              <div className="content-text col-sm-6">
                <p>
                  Kleiner Schatz, weißt du eigentlich...
                  <br />
                  ... wie lieb dich alle haben? Du machst jeden fröhlich,
                  einfach so. Mit der bebe ZARTPFLEGE® Lippenpflege möchten
                  wir dir ein bisschen Freude zurückgeben: Die speziell für
                  Kinder entwickelte Formel enthält Sheabutter und ist 
                  zu 99,9 % natürlichen Ursprungs. Sie pflegt zarte 
                  Kinderlippen und schützt sie vor dem Austrocknen. 
                  Sie hinterlässt einen Pflegefilm und duftet herrlich
                  fruchtig nach Kirschen. Hautverträglichkeit dermatologisch bestätigt.
                </p>
                <a
                  className="toggle-link"
                  href="/produkte/#inhaltsstoffe"
                >
                  Inhaltsstoffe
                </a>
                <div id="inhaltsstoffe" className="toggle-content">
                  <p>
                    [PR-018683]
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        "line-height": "1.42857143"
                      }}
                    >
                      Ricinus Communis (Castor) Seed Oil, Cera Alba (Beeswax),
                      Helianthus Annuus (Sunflower) Seed Oil, Cocos Nucifera (Coconut) Oil,
                      Butyrospermum Parkii (Shea) Butter, Hydroxystearic/Linolenic/Oleic 
                      Polyglycerides, Helianthus, Annuus (Sunflower) Seed Wax,
                      Aroma (Flavor), Tocopherol, Prunus Avium (Sweet Cherry) 
                      Seed Oil, Caprylic/Capric, Triglyceride, Argania Spinosa 
                      Kernel Oil, Rosa Damascena, Flower Extract, Citric Acid,
                      CI 77891 (Titanium Dioxide), CI 77491 (Iron Oxides).
                    </span>
                  </p>
                </div>
              </div>
            </div> */}
            </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
